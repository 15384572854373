<template>
<div class="col-md-12 mtop-15">          
	<div class="box status-box">
	  <div class="box-header with-border">
		<h3 class="box-title">Status Audit</h3>
		<div class="box-tools pull-right">
		  <button type="button" class="btn btn-box-tool cancel-widget"><i class="fa fa-times"></i></button>
		</div>
	  </div>
	  <div class="box-body">
		<table class="table  table-striped" style="width:100%;">
		  <thead>
			<tr>
			  <th class="text-capitalize">
				Time
			  </th>
			  <th class="text-capitalize">
				Modifier
			  </th>
			  <th class="text-capitalize">
				Status
			  </th>
			  <th class="text-capitalize">
				Action
			  </th>
			  <th class="text-capitalize">
				Assigned To
			  </th>
			  <th class="text-capitalize">
				Topic
			  </th>
			</tr>
		  </thead>
		  <tbody>
			<tr>
			  <td>
				11/11/2017 12:19:20PM
			  </td>
			  <td>
				Jaswantchouhan
			  </td>
			  <td>
				<small class="label label-success">Resolved</small>
			  </td>
			  <td>
				Contact 181 - [#Myqueue:MessageQueue QouteRequest / QouteSend] Form submit(webapp-api)
			  </td>
			  <td>
				Jaswantchouhan
			  </td>
			  <td>
				JC - Segment- All Topic - To- Quote - Open Quotes
			  </td>
			</tr>
		  </tbody>
		</table>
	  </div>
	</div>
</div>
</template>
<template>
  <div class="col-md-12 full-case-panel mtop-15">
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Full Case History - Ref#: 42733</h3>
        <div class="box-tools pull-right">
          <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
        </div>
      </div>
      <div class="box-body">
        <div class="full-case-panel-data">
           <table class="table">
             <tbody>
               <tr id="row3">
                    <td name="name" style="width:40px !important;">
                      <button class="btn btn-xs btn-link btn-expand" v-on:click="toggleHistoryRow('fc1')">
                      <i class="fa fa-minus-circle" v-if="rowfc1"></i>
                      <i class="fa fa-plus-circle" v-if="!rowfc1"></i>
                      </button>
                    </td>
                    <td>[ref#: 42733-42733] Feedback provided</td>
                    <td> All Topic</td>
                    <td><small class="label label-with-icon bg-blue"><i class="fa fa-envelope"></i>Customer Email</small></td>
                    <td>--</td>
                    <td><small class="bg-black disabled label">2018-08-03 11:55:44 AM</small></td>
                    <td>&nbsp;</td>
                </tr>
                <ExpandedRowFullCase v-if="rowfc1" rowValue="fc1" dataMode="fc1" replyBG = "bg-agent-reply"></ExpandedRowFullCase>
                <tr id="row2" class="bg-customer-reply">
                    <td name="name" class="" style="width:40px !important;">
                      <button class="btn btn-xs btn-link btn-expand" v-on:click="toggleHistoryRow('fc2')">
                        <i class="fa fa-minus-circle" v-if="rowfc2"></i>
                        <i class="fa fa-plus-circle" v-if="!rowfc2"></i>
                      </button>
                    </td>
                    <td>[ref#: 42733-42733] Feedback provided</td>
                    <td> All Topic</td>
                    <td><small class="label bg-green label-with-icon"><i class="fa icon-c icon-agent-email-3"></i>Agent Email</small></td>
                    <td>--</td>
                    <td><small class="bg-black disabled label">2018-08-03 11:55:44 AM</small></td>
                    <td></td>
                </tr>
                <ExpandedRowFullCase v-if="rowfc2"   rowValue="fc2" dataMode="fc2" replyBG = "bg-customer-reply" ></ExpandedRowFullCase>
             </tbody>
           </table>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import  ExpandedRowFullCase from '../components/ExpandedRowFullCase.vue';
import ExpandedRowViewMode from '../components/ExpandedRowViewMode.vue';
  export default{
        name: 'Full Case History',
        props: ['tabType'],
        components: {
            ExpandedRowFullCase
        },
        data() {
            return{
                rowfc1 : false,
                rowfc2 : false
            }
        },
        methods: {
            toggleHistoryRow(i) {
                this['row'+i] = !this['row'+i] 
            }
        }
  }
</script>
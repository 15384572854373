<template>
    
<div class="nav-tabs-custom">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span>Email Addresses & Subject for Response</span></a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'This interaction has file attachments.',theme: {placement: 'bottom', }}"><i class="fa fa-paperclip"></i> <span>Details & Attachments</span><span class="new-inside"></span></a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property values set for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span><span class="new-inside"></span></a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane show active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
            <!--Standard Response start-->
            <div class="row">

                <div class="col-md-8 form-horizontal">
                    <div class="form-group">
                    <div class="col-2">
                    <label class="control-label text-left">From</label>
                    </div>
                    <div class="col-10"> 
                        <Multiselect
                            v-model="value"
                            :closeOnSelect="false"
                            placeholder="--Select Mailbox--"
                            :options="{
                                customersupport: 'customer support (support@example.com)',
                                sales: 'Sales (sales@example.com)',
                                technical: 'Technical Support (technical@example.com)'
                            }"
                            />
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-2 control-label text-left">To</label>
                    <div class="col-10">
                        <select class="form-control select2 is-invalid">
                          <option>Select to address</option>
                          <option selected="selected">Jacob.Smith@1to1Service.Com</option>
                        </select>
                        <div class="invalid-feedback"><span>To Address required</span></div>
                    </div>
                    </div>
                
                    <div class="form-group gx-5">
                    <label class="col-2 control-label text-left">CC</label>
                    <div class="col-8 replyall">
                        <i class="fa  fa-mail-reply-all  mright-5" v-tooltip="{text: 'This question has addresses in the CC line. Click to add them to your reply.',theme: {placement: 'bottom', }}"></i>
                        <input class="form-control" type="text">
                    </div>
                    <div class="col-2 pos-rel">
                        <a class="btn-link bcc-link cp" v-tooltip="{text: 'Click to add a BCC line to your reply.',theme: {placement: 'bottom', }}" v-if="showBCC" v-on:click="showBCC = !showBCC">Add BCC</a>
                    </div>  
                    </div>
                
                    <div class="form-group" v-if="!showBCC">
                    <label class="col-2 control-label text-left">BCC</label>
                    <div class="col-10">
                        <input class="form-control" type="text">
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-2 control-label text-left">Subject</label>
                    <div class="col-4">
                        <input class="form-control" type="text" value="Ref#: 123456789-123456789" disabled>
                    </div>
                    <div class="col-6">
                        <input class="form-control is-invalid" type="text" value="looking for low cost tv">
                        <div class="invalid-feedback"><span>Subject required</span></div>
                    </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <iservice-files-upload ng-init="uploads = []" files="uploads"></iservice-files-upload>
                    <div class="form-group">
                        <div class="col-md-12">
                            <label class="drop-box">
                            Drop files here or click to upload
                              <input type="file" class="ng-hide" style="display:none;" multiple="">
                            </label>
                            <div>(Maximum 10 MB upload at one time.)</div>
                            <div class="imageuploadify">
                            <!-- ngRepeat: file in files -->
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
                <!--Standard Response End-->
        </div>
    
        <div class="tab-pane fade" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
          <DetailsandattachmentsTabContent></DetailsandattachmentsTabContent>
        </div>

        <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
           <InteractionPropertyTab></InteractionPropertyTab>
        </div>

    </div>
</div>


</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import Multiselect from '@vueform/multiselect'

    export default {
      name: 'EmailAddressSubjectResponseComponents',
      props: ['tabOption'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent
      },
      mounted(){
           
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
          }
      }
    }
</script>
<template>
  <CaseProperty v-if="fullcaseview"></CaseProperty>
  <tr class="expandedrowcontent">
     <td class="white-bg">&nbsp;</td>
     <td colspan="7"  class="p-0">
        <table class="table mb-0">
          <tbody>
            <tr id="row_1" class="bg-customer-reply">
              <td name="name" class="" style="width:40px !important;">
                 <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                   <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                   <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
                </button></td>
              <td style="width:376px;">[ref#: 42733-42733] I need help picking a camera</td>
              <td>	All Topics</td>
              <td><small class="bg-green-active label">Resolved</small></td>
              <td><small class="label bg-blue disabled label-with-icon"><i class="fa fa-comments"></i>Chat</small></td>
              <td>	Jacob Smith	</td>
              <td>2016-07-18 9:36:26 AM</td>
            </tr>
            <tr class="expandedrowcontent bg-customer-reply" v-if="showexpanded1">
            <td>&nbsp;</td>
              <td colspan="7">
                 <ViewModeContent v-if="viewmodecontent"></ViewModeContent>

                 <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                 <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                 <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                 <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                 <GetThisContent v-if="getthiscontent"></GetThisContent> 
              </td> 
            </tr>
          </tbody>
        </table>
     </td>

  </tr>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContent from '../components/ViewModeContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';
import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateTicketContent from '../components/CreateTicketContent.vue';
import ForwardContent from '../components/ForwardContent.vue';
import GetThisContent from '../components/GetThisContent.vue';

import CaseProperty from '../components/CaseProperty.vue';



import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Expanded Row',
      props: ['tabOption'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        ViewModeContent,
        CreateNoteContent,
        AgentEmailContent,
        CreateTicketContent,
        ForwardContent,
        GetThisContent,
        CaseProperty
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            viewmodecontent : true,
            createnotecontent : false,
            agentemailcontent : false,
            createticketcontent : false,
            forwardcontent  : false,
            getthiscontent : false,
            fullcaseview: false
          }
      },
      mounted(){
           
           this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
            });
            this.emitter.on("hide-view-details", Data => {
              this[Data.displaySegment] = false;
              this.viewmodecontent = true;
            });
      }
    }
</script>
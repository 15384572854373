<template>
    <div class="row">
      <div class="col-md-12">
          <div class="form-group">
            
            <button class="btn btn-primary" title="Save Draft">
                <i class="fa fa-mail-forward"></i>
                Forward
            </button>
            <button class="btn btn-primary" title="Save Draft">
                <i class="fa fa-save"></i>
                Save Draft
            </button>
            <button type="button" class="btn btn-link" v-on:click = "cancel('forwardcopycontent')">
                Cancel
            </button>
          </div>

          <div class="nav-tabs-custom form-horizontal">
            <ul class="nav nav-tabs">
                <li class="nav-item active">
                  <a class="nav-link" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false"><i class="fa fa-fw fa-ticket"></i> <span>Ticket Details</span></a>
                  </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false"><i class="fa fa-save"></i> <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="There are property values set for this interactions.">Interaction Properties</span><span class="new-inside"></span></a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                   <div class="row">
                     <div class="col-md-8">
                        <div class="form-group">
                          <label class="col-2 control-label text-left">Topic</label>
                            <div class="col-10">
                                 <Multiselect
                                    v-model="value"
                                    placeholder="Select topic"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-2 control-label text-left">From</label>
                            <div class="col-10">
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-2 control-label text-left">To</label>
                            <div class="col-10">
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword3" class="col-2 control-label text-left">CC</label>
                            <div class="col-10">
                            <textarea class="form-control" rows="2"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail3" class="col-2 control-label text-left">Subject</label>
                            <div class="col-10">
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="col-md-12">
                                <label class="drop-box">
                                Drop files here or click to upload
                               
                                </label>
                                <div>(Maximum 10 MB upload at one time.)</div>
                                <div class="imageuploadify">
                                <!-- ngRepeat: file in files -->
                                </div>
                            </div>
                        </div>
                    
                   </div>
                   
                 </div>

                <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <InteractionPropertyTab></InteractionPropertyTab>
                </div>
                 
            </div>
        </div>
        <DraftBodyTabs></DraftBodyTabs>

        <PrivateComment></PrivateComment>

      </div>
    </div>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContent from '../components/ViewModeContent.vue';

import DraftBodyTabs from '../components/DraftBodyTabs.vue';
import PrivateComment from '../components/PrivateComment.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Create Ticket Content',
      props: ['tabOption'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DraftBodyTabs,
        PrivateComment
      },
      mounted(){
          
      },
      data: function(){
          return{
            date: new Date(),
            timezone: '',
            sendBtn: false,
          }
      },
      methods: {
          cancel(segment){
              this.emitter.emit("hide-view-details",{displaySegment:segment});
            }
      }
    }
</script>
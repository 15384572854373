<template>
 <div class="row">
    <div class="col-md-12">
        <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Send new agent email to John Doe"  v-on:click="show('agentemailcontent')">
            <i class="fa fa-envelope"></i>
            Agent email 
        </button>
        <button class="btn  btn-success margin-r-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add note to John Doe" v-on:click="show('createnotecontent')">
        <i class="fa fa-pencil-square-o"></i>
        Note
        </button>
        <button class="btn  btn-warning margin-r-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Create Ticket for John Doe" v-on:click="show('createticketcontent')">
        <i class="fa fa-ticket"></i>
        Ticket
        </button>
        <button class="btn  btn-purple margin-r-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Forward a copy of this via email" v-on:click="show('forwardccopyontent')">
        <i class="fa fa-mail-forward"></i>
        Forward Copy
        </button>
        <div class="btn-group pull-right dropdown" auto-close="outsideClick">
            <button type="button" class="btn btn-default" v-on:click = "toggleMore = !toggleMore" data-bs-toggle="tooltip" data-bs-placement="bottom" title="more">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-right dropdown-bg dropdown-more" v-bind:class = "toggleMore?'':'show'">
                <li role="menuitem" ng-class="{ 'bg-primary': isStatusAuditSectionExpanded }">
                    <iservice-switch switched="toggle.switchshowstatus">
                        <label class="switch">
                            <input type="checkbox" ng-model="toggle.switchshowstatus" iservice-switch="" class="ng-pristine ng-untouched ng-valid ng-empty" style=""><i></i>
                            <ng-transclude>Show Status Audit</ng-transclude>
                        </label>
                    </iservice-switch>
                </li>
                <li role="menuitem">
                    <iservice-switch switched="toggle.switchshowfullcase">
                        <label class="switch">
                            <input type="checkbox" ng-model="toggle.switchshowfullcase" iservice-switch="" class="ng-pristine ng-untouched ng-valid ng-empty" style=""><i></i>
                            <ng-transclude>Show Full Case</ng-transclude>
                        </label>
                    </iservice-switch>
                </li>
                <li role="menuitem">
                    <iservice-switch switched="toggle.switchplainorhtml">
                        <label class="switch">
                            <input type="checkbox" ng-model="toggle.switchplainorhtml" iservice-switch="" class="ng-pristine ng-untouched ng-valid ng-empty" style=""><i></i>
                            <ng-transclude>Display Plain Text</ng-transclude>
                        </label>
                    </iservice-switch>
                </li>
                <li role="menuitem"><a href="#"><i class="fa fa-print"></i> Print</a></li>
                <li role="menuitem" class="text-red">
                    <a href="unsafe:javascript:void(0)" class="text-red" v-on:click="showconfirmdelete  = true;" v-show="!showconfirmdelete" style=""><i class="fa  fa-trash"></i> Delete This Interaction</a>
                    <div v-show="showconfirmdelete" class="ng-hide" style="">
                        <p class="text-red">Do you really want to delete this interaction?</p>
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="cancel('getthis');toggleRow('row1'); showDeleteSuccess = true;">Confirm Deletion</button>
                            <button type="button" class="btn btn-link" v-on:click="showconfirmdelete = false">Cancel</button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <button class="btn btn-info margin-r-5 pull-right" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Assign this to me" v-on:click="show('getthiscontent')">
        <i class="fa fa-sign-in"></i>
        Get This
        </button>
    </div>

    <div class="col-md-12">    
        <div class="nav-tabs-custom mtop-15">
            <ul class="nav nav-tabs" id="myTab" role="tablist">

                <li class="nav-item" role="presentation">
                    <a class="nav-link active" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" ><i class="fa fa-paperclip"></i> <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="This interaction has file attachments.">Details & Attachments</span><span class="new-inside"></span></a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false"><i class="fa fa-fw fa-exchange"></i> <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="There are property values set for this interactions.">Interaction Properties</span><span class="new-inside"></span></a>
                </li>
                
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab4'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_4_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false"><i class="fa fa-fw fa-pencil-square-o"></i> <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="There is private comments for this interactions.">Private Comment</span><span class="new-inside"></span></a>
                </li>

            </ul>
            <div class="tab-content" id="myTabContent">
            
                <div class="tab-pane show active" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                   <DetailsandattachmentsTabContent></DetailsandattachmentsTabContent>
                </div>

                <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <InteractionPropertyTab></InteractionPropertyTab>
                </div>
            
                <div class="tab-pane fade" v-bind:id="'tab_4_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <div class="row form-horizontal">
                    <div class="col-md-12">
                        Please show me the other ranges in Plasma tv.
                    </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="message-box">
        <div class="message-box-body">
            <div class="clear"></div>
            <div>
            <p>
                Hello,<br>
                I'm looking for a flat screen. Any suggestions?
            </p>
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';


import StatusAudit from '../components/StatusAudit.vue';
import FullCaseHistory from '../components/FullCaseHistory.vue';


import Multiselect from '@vueform/multiselect'
 import Toggle from '@vueform/toggle'
    export default {
      name: 'View Mode Content History',
      props: ['tabOption','caserowValue','casedataMode'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        StatusAudit,
        FullCaseHistory,
        Toggle
      },
      mounted(){
           
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            toggleMore : true,
            statusaudit : false,
            fullcasehistory : true,
            tabOption : this.tabOption,
            showconfirmdelete:false
          }
      },
       methods: {
            show(segment){
              this.emitter.emit("show-viewhistory-details",{displaySegment:segment});
            }
            
        }
    }
</script>


<template>
    <div class="row">

      <div class="col-md-12">
          <div class="form-group">
            <div class="btn-group dropdown margin-r-5">
                <button type="button" class="btn btn-primary"> <i class="fa fa-send"></i>
                Send</button>										 
                <div class="btn-group">
                    <button type="button" class="btn btn-primary dropdown-toggle" v-on:click=" sendBtn = !sendBtn " >
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-btn-option" id="btnSend" v-bind:class = "sendBtn?'show':''" v-if="sendBtn" v-click-outside="closeEvent">
                        <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-send-leave-open"></i> Leave Open</li>
                        <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-expect-customer-reply"></i> Expect Customer Reply</li>
                        <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-add-reference-number"></i> Add Reference Number</li>
                        <li>
                        <a href="javascript:void(0)" class="text-primary" v-on:click="shownotificationdropdown = !shownotificationdropdown" ><i class="fa icon-c icon-send-secure"></i> Send Secure (Notification)..</a>
                        <div v-if="shownotificationdropdown">
                            <select class="form-control">
                            <option>Select a notification</option>
                            </select>
                        </div>
                        <div class="callout callout-small callout-info mtop-5">
                            <h4> This Segment does not have a Secure Notification auto response configured. </h4>
                            <p> You must create a Secure Notification auto response before using the Send Secure action.</p>
                        </div>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="btn btn-primary" v-tooltip="{text: 'Save draft',theme: {placement: 'bottom', }}">
                <i class="fa fa-save"></i>
                Save Draft
            </button>
            <button type="button" class="btn btn-link" @click="cancel('agentemailcontent')">
                Cancel
            </button>
          </div>
          <div class="row">
            <div class="col-md-12 mbottom-15 form-alert-message ">
                <div class="alert alert-danger alert-dismissible">
                    <button type="button" class="close">×</button>
                    <div class="message-container">
                       <div class="icon">
                          <i class="icon fa fa-ban"></i>
                       </div>
                        <div class="message">
                            <strong>Agent Email</strong>
                            <ul>
                              <li> Please enter a subject for your note.</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
          </div>
          <div class="nav-tabs-custom form-horizontal">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link active" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span>Agent Email Details</span></a>
                  </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property values set for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span><span class="new-inside"></span></a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                   <div class="row">
                     
                     <div class="col-md-8">
                       <div class="form-group">
                            <label class="col-2 control-label text-left">From</label>
                            <div class="col-10">
                               <Multiselect
                                v-model="value"
                                :closeOnSelect="false"
                                placeholder="--Select Mailbox--"
                                :options="{
                                    customersupport: 'customer support (support@example.com)',
                                    sales: 'Sales (sales@example.com)',
                                    technical: 'Technical Support (technical@example.com)'
                                }"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-2 control-label text-left">To</label>
                            <div class="col-10">
                                <select class="form-control select2 is-invalid">
                                   <option>Select To Address</option>
                                  <option selected="selected" value="Jacob.Smith@1to1Service.Com">Jacob.Smith@1to1Service.Com</option>
                                </select>
                                 <div class="invalid-feedback"><span>Address required</span></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-2 control-label text-left">CC</label>
                            <div class="col-10">
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-2 control-label text-left">BCC</label>
                            <div class="col-10">
                                <input class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-2 control-label text-left ">Subject</label>
                            <div class="col-10">
                                <input class="form-control is-invalid" type="text" value="looking for low cost tv">
                                 <div class="invalid-feedback"><span>Subject required</span></div>
                            </div>
                        </div>
                        <div class="form-group">
                          <label class="col-2 control-label text-left">Topic</label>
                            <div class="col-10">
                                 <Multiselect
                                    v-model="value"
                                    placeholder="Select topic"
                                    label="name"
                                    class="is-invalid"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                                <div class="invalid-feedback"><span>Please select Topic</span></div>
                            </div>
                        </div>
                      
                    </div>
                    <div class="col-md-4">
                        <div class="col-md-12">
                                <label class="drop-box">
                                Drop files here or click to upload
                               
                                </label>
                                <div>(Maximum 10 MB upload at one time.)</div>
                                <div class="imageuploadify">
                                <!-- ngRepeat: file in files -->
                                </div>
                            </div>
                        </div>
                    
                   </div>
                   
                 </div>

                <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <InteractionPropertyTab></InteractionPropertyTab>
                </div>
                 
            </div>
        </div>
        <DraftBodyTabs></DraftBodyTabs>

        <PrivateComment></PrivateComment>

      </div>
    </div>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContent from '../components/ViewModeContent.vue';

import DraftBodyTabs from '../components/DraftBodyTabs.vue';
import PrivateComment from '../components/PrivateComment.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Agent Email Content',
      props: ['tabOption','caserowValue', 'caserowMode'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DraftBodyTabs,
        PrivateComment
      },
      mounted(){
         
      },
      data: function(){
          return{
            date: new Date(),
            timezone: '',
            sendBtn: false,
          }
      },
      methods: {
          cancel(segment){
              this.emitter.emit("hide-view-details",{displaySegment:segment});
            }
      }
      
    }
</script>
<template>
<tr class="ng-scope">
   <td class="white-bg"></td>
   <td colspan="7" class="bg-case-properties">
      <div class="box box-info">
         <div class="box-body">
            <div class="row">
               <div class="col-md-12">
                  <h4>Case Properties</h4>
                  <div class="row form-verticle">
                     <div class="col-md-4">
                        <div class="row form-group">
                           <label class="col-md-12 control-label text-left required">Change Request Type <span class="required">(Required)</span></label>
                           <div class="col-md-12">
                              <input type="text" class="form-control pull-right">
                           </div>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <div class="row form-group">
                           <label class="col-md-12 control-label text-left">Delivery Date</label>
                           <div class="col-md-12">
                              <div class="input-group date">
                                 <div class="input-group-addon" ng-click="isDatepickerOpenDelivery = !isDatepickerOpenDelivery">
                                    <i class="fa fa-calendar"></i>
                                 </div>
                                 <input type="text" class="form-control pull-right ng-pristine ng-untouched ng-isolate-scope ng-empty ng-invalid ng-invalid-required ng-valid-date" ng-focus="isDatepickerOpenDelivery = !isDatepickerOpenDelivery" uib-datepicker-popup="dd/MM/yyyy" ng-model="date" is-open="isDatepickerOpenDelivery" datepicker-options="datepickerOptions" ng-required="true" close-text="Close" alt-input-formats="altInputFormats" required="required" style="">
                                 <div uib-datepicker-popup-wrap="" ng-model="date" ng-change="dateSelection(date)" template-url="uib/template/datepickerPopup/popup.html" class="ng-pristine ng-untouched ng-valid ng-scope ng-empty" style="">
                                    <!-- ngIf: isOpen -->
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <div class="row form-group">
                           <label class="col-md-12 control-label text-left">Detail</label>
                           <div class="col-md-12">
                              <textarea class="form-control" rows="3"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
                  <button class="btn btn-primary" type="button" ng-click="">
                  Save
                  </button>
               </div>
            </div>
         </div>
      </div>
   </td>
</tr>
</template>
<template>
<div class="row">
   <div class="col-md-12 form-action">
      <ActionModeButtons></ActionModeButtons> 

      <div class="row">
         <div class="col-md-12">
            <EmailAddressSubjectResponse tabOption="3"></EmailAddressSubjectResponse>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12" >
            <DraftBodyTabs></DraftBodyTabs>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <div class="panel panel-default form-group">
               <div class="panel-heading">
                  <i class="fa fa-fw fa-pencil-square-o"></i>
                  Private Comments
               </div>
               <div class="panel-body">
                  <textarea class="full-width full-height"></textarea>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
import ActionModeButtons from '../components/ActionModeButtons.vue';
import EmailAddressSubjectResponse from '../components/EmailAddressSubjectResponse.vue';
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
import DraftBodyTabs from '../components/DraftBodyTabs.vue';




export default {
  name: 'Get This Content',
   components: {
      ActionModeButtons,
      EmailAddressSubjectResponse,
      VariableAndStockResponses,
      DraftBodyTabs
  },
  data: function(){
      return{
       quote:true,
       message:false,
       showDetails :false
      }
  }

 
}
</script>
<template>
   <tr class="expandedrowcontent" v-bind:class = "replyBG">
        <td>&nbsp;</td>
        <td colspan="6">
            <ViewModeContentHistory v-if="dynamicData['viewmodecontent'+this.tabOption]" v-bind:caserowValue="rowValue" v-bind:casedataMode="dataMode" v-bind:tabOption = "dataMode"></ViewModeContentHistory>

            <CreateNoteContent v-if="dynamicData['createnotecontent'+this.tabOption]" v-bind:caserowValue="rowValue" v-bind:casedataMode="dataMode" v-bind:tabOption = "dataMode"></CreateNoteContent>

            <AgentEmailContent v-if="dynamicData['agentemailcontent'+this.tabOption]" v-bind:caserowValue="rowValue" v-bind:casedataMode="dataMode" v-bind:tabOption = "dataMode"></AgentEmailContent>

            <CreateTicketContent  v-if="dynamicData['createticketcontent'+this.tabOption]"   v-bind:caserowValue="rowValue" v-bind:casedataMode="dataMode" v-bind:tabOption = "dataMode"></CreateTicketContent>

            <ForwardContent v-if="dynamicData['forwardccopyontent'+this.tabOption]" v-bind:caserowValue="rowValue" v-bind:casedataMode="dataMode" v-bind:tabOption = "dataMode"></ForwardContent>

            <GetThisContent v-if="dynamicData['getthiscontent'+this.tabOption]" v-bind:caserowValue="rowValue" v-bind:casedataMode="dataMode" v-bind:tabOption = "dataMode"></GetThisContent> 
        </td> 
    </tr>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContentHistory from '../components/ViewModeContentHistory.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';
import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateTicketContent from '../components/CreateTicketContent.vue';
import ForwardContent from '../components/ForwardContent.vue';
import GetThisContent from '../components/GetThisContent.vue';
import CaseProperty from '../components/CaseProperty.vue';

import StatusAudit from '../components/StatusAudit.vue';
import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Expanded Row Full Case',
      props: ['tabOption','rowValue','dataMode','replyBG'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        ViewModeContentHistory,
        CreateNoteContent,
        AgentEmailContent,
        CreateTicketContent,
        ForwardContent,
        GetThisContent,
        CaseProperty,
        StatusAudit
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            dynamicData:[],
            showexpanded1: false,
            viewmodecontentfc1 : true,
            createnotecontent : false,
            createnotecontentfc1 : false,
            createticketcontent : false,
            forwardcontent  : false,
            getthiscontent : false,
            replyBG : this.replyBG,
            dataMode : this.dataMode,
            rowValue : this.rowValue,
            tabOption : this.dataMode

          }
      },
      mounted(){
          
           this.dynamicData['viewmodecontent'+this.tabOption] = true; 
           this.dynamicData['agentemailcontent'+this.tabOption] = false;
           this.dynamicData['createticketcontent'+this.tabOption] = false;
           this.dynamicData['createnotecontent'+this.tabOption] = false;
           this.dynamicData['forwardccopyontent'+this.tabOption] = false;
           this.dynamicData['getthiscontent'+this.tabOption] = false;
  
           this.emitter.on("show-viewhistory-details", Data => {
              this.dynamicData[Data.displaySegment+this.tabOption] = true;
              this.dynamicData['viewmodecontent'+this.tabOption]  = false;
            });
            this.emitter.on("hide-view-details", Data => {
              
                this.dynamicData['viewmodecontent'+this.tabOption] = true; 
                this.dynamicData['agentemailcontent'+this.tabOption] = false;
                this.dynamicData['createticketcontent'+this.tabOption] = false;
                this.dynamicData['createnotecontent'+this.tabOption] = false;
                this.dynamicData['forwardccopyontent'+this.tabOption] = false;
                this.dynamicData['getthiscontent'+this.tabOption] = false;
            });
      }
    }
</script>